const authorityLevel = {
  'pages.platform.user.role.name': 'Role Name',
  'pages.platform.user.role.description': 'Description',
  'pages.platform.user.role.add.role': 'Add Role',
  'pages.platform.user.role.function.permissions': 'Function Permissions',
  'pages.platform.user.role.data.permissions': 'Data Permissions',
  'pages.platform.user.role.role.information': 'Role Information',
  'pages.platform.user.role.role.permission': 'Role Permission',
  'pages.platform.user.role.reference.role': 'Reference Role',
  'pages.platform.user.role.view.staff': 'View Staff',
  'pages.platform.user.role.staff': 'Staff',
  'pages.platform.user.role.category': 'category',
  'pages.platform.user.role.organization': 'Organization',
  'pages.platform.user.role.person': 'person',
  'pages.platform.user.role.custom.department': 'Custom Department',
  'pages.platform.user.role.category.all': 'Category All',
  'pages.platform.user.role.department.all': 'Department All',
  'pages.platform.user.role..departments': 'Departments',
}

export default authorityLevel
