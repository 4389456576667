const user = {
  'pages.platform.user.role.name': 'Role Name',
  'pages.platform.organization.user.name': 'User Name',
  'pages.platform.organization.user.registration.time': 'Registration Time',
  'pages.platform.organization.user.last.login.time': 'Last Login Time',
  'pages.platform.organization.user.organization.name': 'Organization Name',
  'pages.platform.organization.user.department': 'Department',
  'pages.platform.organization.user.position': 'Position',
  'pages.platform.organization.basic.information': 'Basic Information',
  'pages.platform.organization.user.organization': 'Organization',
  'pages.platform.organization.user.update.password': 'Update Password',
}

export default user
