const platformOrganization = {
  'pages.platform.organization.organization.name': 'Organization Name',
  'pages.platform.organization.organization.owner': 'Owner',
  'pages.platform.organization.organization.owner.phone': 'Owner Phone',
  'pages.platform.organization.organization.main.verify': 'Main Verify',
  'pages.platform.organization.organization.abbreviation.name': 'Organization Abbreviation Name',
  'pages.platform.organization.organization.code': ' Organization Code',
  'pages.platform.organization.organization.concat.name': 'Concat Name',
  'pages.platform.organization.organization.concat.phone': 'Concat Phone',
  'pages.platform.organization.organization.approval.status': 'Approval Status',
  'pages.platform.organization.organization.basic.information': 'Basic Information',
  'pages.platform.organization.organization.invoice.information': 'Invoice Information',
  'pages.platform.organization.organization.company.name': 'Company Name',
  'pages.platform.organization.organization.company.address': 'Company Address',
  'pages.platform.organization.organization.company.detail.address': 'Detail Address',
  'pages.platform.organization.organization.social.credit.code': 'Social Credit Code',
  'pages.platform.organization.organization.company.register.address': 'Company Register Address',
  'pages.platform.organization.organization.company.register.phone': 'Company Register  Phone',
  'pages.platform.organization.organization.deposit.bank': 'Deposit Bank',
  'pages.platform.organization.organization.deposit.bank.account': 'Deposit Bank Account',
  'pages.platform.organization.organization.company.certificate.address': 'Certificate Address',
  'pages.platform.organization.organization.corporate.juridical.person': 'Juridical Person',
  'pages.platform.organization.organization.corporate.identity.card': 'Corporate Identity Card',
  'pages.platform.organization.organization.business.license': 'Business License',
  'pages.platform.organization.organization.official.letter': 'Official Letter',
  'pages.platform.organization.organization.menu.configuration': 'Menu Configuration',
  'pages.platform.organization.organization.company.approve': 'Company Approve',
  'pages.platform.organization.organization.reason': 'Reason',
  'pages.platform.organization.organization.approve': 'Approve Pass',
  'pages.platform.organization.organization.approve.reject': 'Approve Reject',
  'pages.platform.organization.organization.reject': 'Reject',
}

export default platformOrganization
